export interface Address {
  langcode: string;
  country_code: string;
  locality: string;
  postal_code: string;
  address_line1: string;
  address_line2: string;
  region?: string;
}

export interface BannerImage {
  url: string;
  alt: string;
  width: number;
  height: number;
}

export interface Path {
  alias: string;
}

export interface Description {
  summary: string;
  processed: string;
  value: string;
}

export interface FieldColor {
  color: string;
}

export interface FieldLogo {
  url: string;
}

export interface Brand {
  name: string;
  brand_code: string;
  description: Description;
  path: Path;
  field_color: FieldColor;
  relationships: {
    field_logo: FieldLogo;
  };
}

export interface HotelCardAmenity {
  name: string;
  machine_name: string;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface Tag {
  name: string;
}

export interface Hotel {
  hotelId: string;
  uniqueIdentifier: string;
  name: string;
  crs_code: string;
  crs_name: string;
  hotel_code: string;
  field_occupancy_messaging: string;
  field_phone_require: string;
  address: Address;
  banner_images: BannerImage[];
  phone: string;
  path: Path;
  relationships: {
    brand_id?: Brand;
    field_tags?: Tag;
  };
  hotelCardAmenities: HotelCardAmenity[];
  placeId: string;
  coordinates: Coordinates;
  secondaryCity: string;
  smartScore: number;
  tagline: string;
  galleryImages: { alt: string; url: string }[] | null; // Updated to be nullable
}

export interface DynamicHotel {
  index: number;
  address: {
    country: string;
    region: string;
  };
  crsHotelCode: string;
  rangeInMilesFromOrigin: number;
}

export const constructUrlObject = (staticHotel: Hotel): string | null => {
  return staticHotel.path?.alias ?? null;
};

export const constructAddressObject = (
  staticHotel: Hotel,
  dynamicHotel: DynamicHotel
) => {
  return {
    locality: staticHotel.address?.locality ?? "",
    streetAddress1: staticHotel.address?.address_line1 ?? "",
    postalCode: staticHotel.address?.postal_code ?? "",
    cityName: staticHotel.address?.locality ?? "",
    country: dynamicHotel.address.country,
    region: dynamicHotel.address.region,
  };
};

export const getGalleryUrl = (
  galleryImages: { alt: string; url: string }[] | null
): string | null => {
  return galleryImages && galleryImages.length > 0
    ? galleryImages[0].url
    : null;
};

export const findStaticHotel = (
  staticHotels: Record<string, Hotel>,
  crsHotelCode: string
): Hotel | undefined => {
  return staticHotels[crsHotelCode];
};

export const constructBrandObject = (staticHotel: Hotel) => {
  return {
    name: staticHotel.relationships?.brand_id?.name ?? "",
    code: staticHotel.relationships?.brand_id?.brand_code ?? "",
    logoUrl:
      staticHotel.relationships?.brand_id?.relationships?.field_logo?.url ?? "",
  };
};

export const getTags = (staticHotel: Hotel): string => {
  return staticHotel.relationships?.field_tags?.name ?? "";
};

export const addMissingFields = (
  hotel: any,
  staticHotel: Hotel,
  dynamicHotel: DynamicHotel
) => {
  return {
    ...hotel,
    crsHotelCode: staticHotel?.crs_code ?? "",
    hotelCode: staticHotel?.hotel_code ?? "",
    crsName: staticHotel?.crs_name ?? "",
    rangeInMilesFromOrigin: dynamicHotel?.rangeInMilesFromOrigin ?? 0,
    index: dynamicHotel?.index,
  };
};

export const mergeHotelProperties = (
  staticHotel: Hotel,
  dynamicHotel: DynamicHotel
) => {
  const fieldsToAdd: (keyof Hotel)[] = [
    "hotelId",
    "name",
    "smartScore",
    "secondaryCity",
    "phone",
    "coordinates",
    "tagline",
    "galleryImages",
    "uniqueIdentifier",
  ];

  const mergedHotel: any = {};

  fieldsToAdd.forEach((field) => {
    if (staticHotel[field] !== undefined) {
      mergedHotel[field] = staticHotel[field] ?? getDefaultFieldValue(field);
    }
  });

  if (staticHotel.relationships?.brand_id) {
    mergedHotel.brand = constructBrandObject(staticHotel);
  }

  if (staticHotel.address) {
    mergedHotel.address = constructAddressObject(staticHotel, dynamicHotel);
  }

  if (staticHotel.galleryImages) {
    mergedHotel.gallery = getGalleryUrl(staticHotel.galleryImages);
  }

  mergedHotel.tags = getTags(staticHotel);

  if (staticHotel.path) {
    mergedHotel.url = constructUrlObject(staticHotel);
  }

  return addMissingFields(mergedHotel, staticHotel, dynamicHotel);
};

const getDefaultFieldValue = (field: keyof Hotel) => {
  switch (field) {
    case "hotelId":
    case "uniqueIdentifier":
    case "name":
    case "crs_code":
    case "crs_name":
    case "hotel_code":
    case "field_occupancy_messaging":
    case "field_phone_require":
    case "phone":
    case "secondaryCity":
    case "tagline":
      return "";
    case "smartScore":
      return 0;
    case "coordinates":
      return { latitude: 0, longitude: 0 };
    case "galleryImages":
    case "hotelCardAmenities":
      return [];
    default:
      return null;
  }
};

export const mergeHotels = (
  staticHotels: Record<string, Hotel>,
  dynamicHotels: Record<string, DynamicHotel>
): Record<string, any> => {
  const mergedHotels: Record<string, any> = {};
  for (const key in dynamicHotels) {
    const dynamicHotel = dynamicHotels[key];
    const crsHotelCode = dynamicHotel.crsHotelCode;
    const staticHotel = findStaticHotel(staticHotels, crsHotelCode);
    if (staticHotel) {
      mergedHotels[key] = mergeHotelProperties(staticHotel, dynamicHotel);
    }
  }
  return mergedHotels;
};
