import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export interface IApolloClientProps {
    apiEndpoint?: string;
    token?: string;
}

export class ApolloBaseClient {    
    protected client: ApolloClient<any>;
        
    constructor(params: IApolloClientProps) {
        const httpLink = new HttpLink({
            uri: params.apiEndpoint,
          });
        const authLink = setContext((_, { headers }) => {
            return {
                headers: {
                ...headers,
                "Ocp-Apim-Subscription-Key": `${process.env.GATSBY_CDP_SUBSCRIPTION_KEY}`,
                "user-agent": "Sonesta/1.1.1 CFNetwork/1327.0.4 Darwin/21.3.0 (iPhone/13 iOS/15.2)",
                "Cache-Control": "no-cache",
                "content-type": "application/json",
                }
            };
        });
        const link = ApolloLink.from([authLink, httpLink]);
        this.client = new ApolloClient({
            link: link,
            cache: new InMemoryCache()
        });
    }
}