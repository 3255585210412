import { TokenProvider } from "../TokenProvider";
import { fetchReservationAPIConfig } from "./fetchReservationAPIConfig";
import { createAxiosClient } from "../rest/AxiosClient";

export const cancelInitiatedReservation = async (
  reservationTransactionId: string
): Promise<any> => {
  const token = await new TokenProvider().getAnonToken();
  const config = await fetchReservationAPIConfig(token);
  const axiosClient = await createAxiosClient({
    apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
  });
  const response = await axiosClient.deleteData(
    `/reservation/transaction/${reservationTransactionId}/cancel`,
    config
  );
  return response;
};

export default cancelInitiatedReservation;
