import { getLoggedStatus } from "../helpers";
export const addPageTypeInGTMDataLayer = (pageType: string): void => {
  try {
    let epsilonPageType: string, promoId: number | string;
    switch (pageType) {
      case "home":
        epsilonPageType = "Homepage";
        promoId = 1;
        break;
      case "landing page":
      case "brand page":
        epsilonPageType = "Information Pages";
        promoId = 2;
        break;
      case "search":
        epsilonPageType = "Search Results";
        promoId = 7;
        break;
      case "hotel-detail":
        epsilonPageType = "Hotel Details";
        promoId = 4;
        break;
      case "checkout select room":
      case "checkout guest details":
      case "checkout payment details":
        epsilonPageType = "Cart";
        promoId = 5;
        break;
      case "reservation confirmation":
        epsilonPageType = "Conversion Confirmation";
        promoId = "";
        break;
      case "sign up":
        epsilonPageType = "Account Signup";
        promoId = 941;
        break;
      case "account":
      case "reservation":
      case "edit reservation":
        epsilonPageType = "Account Center Pages";
        promoId = 942;
        break;
      default:
        epsilonPageType = pageType;
    }
    window.dataLayer = window.dataLayer || [];
    const loggedState = getLoggedStatus();
    // sendLoginStateInGA();
    window.dataLayer.push({
      pageType: pageType,
      loggedState: loggedState,
      Page: epsilonPageType,
      PromoId: promoId,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
