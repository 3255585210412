import { getCrsFromId } from "./getCrsFromId";
import { getHotelCodeFromId } from "./getHotelCodeFromId";
import { coreGet } from "../core";

export const fetchHotelDetails = async (
  params: {
    crs: string;
    hotelCode?: string;
    resId: string;
  },
  source = null
): Promise<
  | {
      error: boolean;
      errorSummary: any;
      value?: undefined;
    }
  | {
      error: boolean;
      value: any;
      errorSummary?: undefined;
    }
> => {
  const crs = params.crs || getCrsFromId(/* params.resId */);
  const hotelCode = !params.hotelCode
    ? getHotelCodeFromId(params.resId, crs)
    : params.hotelCode;

  const siteId = process.env.SITE_ID || 31;
  const queryString = `HotelCode=${hotelCode}&siteId=${siteId}`;
  const apiUrl = encodeURI(`/${crs}/details?${queryString}`);
  let returnData;
  try {
    const response = await coreGet(apiUrl, source);
    if (response.error) {
      returnData = {
        error: true,
        errorSummary: response.message,
      };
    } else {
      returnData = {
        error: false,
        value: response,
      };
    }
  } catch (error) {
    returnData = {
      error: true,
      errorSummary: "Oops!! Something went wrong, please try again.",
    };
  }
  return returnData;
};

export default fetchHotelDetails;
