import { Product } from "../../@types/IDatalayers";
import { getLoggedStatus } from "../helpers";

export const fireProductDetailEvent = (
  product: Product,
  searchString: string
): void => {
  const loggedState = getLoggedStatus();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "productDetail",
    ecommerce: {
      detail: {
        actionField: { list: searchString },
        products: [product],
      },
    },
    loggedState: loggedState,
  });
};
