import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IBrand from "../../../@types/IBrand";
import { RootState } from "../../store";

export interface IBrandState {
  brands?: Array<IBrand>;
  selectedBrand?: IBrand;
  searchedHotelBrandId?: number;
}

const _initialState: IBrandState = {
  brands: [],
};

const brandSlice = createSlice({
  name: "brand",
  initialState: _initialState,
  reducers: {
    hydrateBrandState: (state: any, { payload }: PayloadAction<any>) => {
      state.brands = [...payload];
    },
    selectBrand: (state: any, { payload }: PayloadAction<any>) => {
      state.selectedBrand = { ...payload };
    },
    setLookUpHotelBrandId: (state: any, { payload }: PayloadAction<any>) => {
      state.searchedHotelBrandId = { ...payload };
    },
  },
});

export const brandReducer = brandSlice.reducer;
export const { hydrateBrandState, selectBrand, setLookUpHotelBrandId } =
  brandSlice.actions;
//Selectors
export const brandSelector = (state: RootState): IBrand | undefined =>
  state.brand.selectedBrand;
export const brandsSelector = (state: RootState): IBrand[] | undefined =>
  state.brand.brands;
export const hotelBrandIdSelector = (state: RootState): number | undefined =>
  state.brand.searchedHotelBrandId;
