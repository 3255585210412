import { TokenProvider } from "../TokenProvider";
import { InitiatePayload } from "./types";
import { AxiosClient } from "../rest/AxiosClient";
import { fetchReservationAPIConfig } from "./fetchReservationAPIConfig";
import { IAxiosClientProps } from "../rest/AxiosBaseClient";

export async function initiateReservation(
  payload: InitiatePayload
): Promise<any> {
  try {
    //check the email is associated with member account
    const token = await new TokenProvider().getAnonToken();
    const config = await fetchReservationAPIConfig(token);
    const axiosClientProps: IAxiosClientProps = {
      apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
    };

    const reservationClient = new AxiosClient(axiosClientProps);

    return await reservationClient.postData(
      "/reservation/transaction/initiate",
      payload,
      config
    );
  } catch (error) {
    console.error("Unexpected Error:", error);
    throw error;
  }
}

export default initiateReservation;
