import { AxiosRequestConfig } from "axios";
import { IAuth0M2MInput } from "../@types/RequestTypes";
import { IAxiosClientProps } from "./AxiosBaseClient";
import { AxiosClient, createAxiosClient } from "./AxiosClient";

export class Auth0Client extends AxiosClient {
  protected client: any;
  public token?: any;
  public clientId?: string;
  public clientSecret?: string;
  public audience?: string;

  constructor(params: IAxiosClientProps) {
    super(params);

    this.client = createAxiosClient(params);
    this.clientId = process.env.GATSBY_CDP_AUTH0_CLIENT_ID;
    this.clientSecret = process.env.GATSBY_AUTH0_CDP_CLIENT_SECRET;
    this.audience = process.env.GATSBY_CDP_AUTH0_AUDIENCE;
  }
  async getAnonUserToken(): Promise<void> {
    const token = this.token;
    const endPoint = `https://${process.env.GATSBY_AUTH0_DOMAIN}/oauth/token`;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const auth0Config: IAuth0M2MInput = {
      client_id: this.clientId || ``,
      client_secret: this.clientSecret || ``,
      audience: this.audience || ``,
      grant_type: `client_credentials`,
    };
    const response = await this.client.client.getPost(
      endPoint,
      auth0Config,
      config
    );
    return response;
  }
  async getMachineToken(): Promise<void> {
    const token = this.token;
    const endPoint = `https://${process.env.GATSBY_AUTH0_DOMAIN}/oauth/token`;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const auth0Config: IAuth0M2MInput = {
      client_id: this.clientId || ``,
      client_secret: this.clientSecret || ``,
      audience: this.audience || ``,
      grant_type: `client_credentials`,
    };
    const response = await this.client.client.post(
      endPoint,
      auth0Config,
      config
    );
    return response;
  }
}
