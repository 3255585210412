import Axios from "axios";
import { AxiosRequestConfig } from "axios";
import { IRequestConfig } from "../@types/ServiceTypes";

const apiUrl =  `${process.env.GATSBY_CORE_BASE_URL}/api`;//https://core.redlion.com/api';
const jsonApiUrl = `${process.env.GATSBY_CORE_CONTENT_BASE_URL}/jsonapi`;

const core = Axios.create({
  baseURL: apiUrl,
  headers: {
    'api-key': process.env.GATSBY_CORE_API_KEY
  }
});
const coreContent = Axios.create({
  baseURL: jsonApiUrl,
  headers: {
    'api-key': process.env.GATSBY_CORE_API_KEY
  }
});

export const coreGet = async (url: string, source: any = null): Promise<any> => {
  let data = null;
  const config: AxiosRequestConfig = {};
  if (source !== null) {
    config.cancelToken = source.token;
  }
  try {
    const response = await core.get(url, config);
    if (response.status === 200) {
      data = response.data;
    }

  } catch (error:any) {
    data = {
      error: true,
      message: error.message
    };
  }
  return data;
};

export const coreGetContent = async (url: string, source: any = null): Promise<any> => {
  let data = null;
  const config: AxiosRequestConfig = {};
  if (source !== null) {
    config.cancelToken = source.token;
  }
  try {
    const response = await coreContent.get(url, config);
    if (response.status === 200) {
      data = response.data;
    }

  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return data;
};

export const coreRequest = async (param: IRequestConfig): Promise<any> => {
  const url = param.url,
    data = param.data || {},
    method = param.method || 'post',
    source = param.source || null,
    options: AxiosRequestConfig = {};
  let responseData: any = null;

  if (source !== null) {
    options.cancelToken = source.token;
  }
  try {
    let response: any;
    if (method === 'post') {
      response = await core.post(url, data, options);
    } else if (method === 'patch') {
      response = await core.patch(url, data, options);
    } else if (method === 'put') {
      response = await core.put(url, data, options);
    }
    const status = response ? response.status : 204;
    if (status === 200 || status === 201) {
      responseData = response.data || response;
    }

  } catch (error:any) {
    responseData = {
      error: true,
      message: 'Oops!! Something went wrong, please try again.',
      apiResponse: error.response && error.response.data
    };
  }
  return responseData;
};
