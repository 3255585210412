import { ITokenResponse } from "./@types/ResponseTypes";
import { Auth0Client } from "./rest/Auth0Client";
import * as authClient from "../utils/auth0Client";

export class TokenProvider {
  public anonToken?: string | null;
  public userToken?: string | null;
  public anonTokenExpiryTime?: any;
  private readonly anonTokenKey = `anonToken`;
  private readonly userTokenKey = `auth-token`;
  private readonly anonTokenExpiryKey = `anonTokenExpiry`;

  constructor() {
    this.anonToken = localStorage.getItem(this.anonTokenKey);
    this.userToken = localStorage.getItem(this.userTokenKey);
    this.anonTokenExpiryTime = localStorage.getItem(this.anonTokenExpiryKey);
  }
  private checkTokenExpired() {
    const currentTime = Date.now();
    // Consider token expired 10 minutes before expiration time
    return this.anonTokenExpiryTime < currentTime + 600000;
  }
  private async getAuth0UserToken(): Promise<string> {
    // change member token
    const token = authClient.getToken();
    return token;
  }
  private async getAuth0MachineToken(): Promise<string> {
    const client = new Auth0Client({});
    const token: ITokenResponse = await client.getMachineToken();
    // const expiryTime = Date.now() + token?.data?.expires_in * 1000;
    // temp code to test expire time functionality - token should expire in 5 mins considering buffer time
    const expiryTime = Date.now() + 900000;
    localStorage.setItem(this.anonTokenExpiryKey, expiryTime);
    return token?.data?.access_token;
  }
  public async getAnonToken(): Promise<string> {
    if (this.anonToken && !this.checkTokenExpired()) {
      return this.anonToken;
    } else {
      const token: string = await this.getAuth0MachineToken();
      localStorage.setItem(this.anonTokenKey, token);
      return token;
    }
  }
  public async getUserToken(): Promise<string> {
    const token: string = await this.getAuth0UserToken();
    return token;
    // if (this.userToken) {
    //   return this.userToken;
    // } else {
    //   const token: string = await this.getAuth0UserToken();
    //   localStorage.setItem(this.userTokenKey, token);
    //   return token;
    // }
  }
}
