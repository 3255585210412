import { IApolloClientProps } from "./ApolloBaseClient";
import { createApolloClient } from "./ApolloClient";
import { TokenProvider } from "../TokenProvider";
import {
  GET_PLACE_DETAILS_RESULTS,
  GET_PLACE_RESULTS,
} from "./SearchServiceQuery";
import {
  PlacesDetailsQuery,
  PlacesSearchQuery,
} from "../../__generated__/types-search";

export class SearchServiceClient {
  public client: any;
  public token: any;

  constructor(params: IApolloClientProps) {
    this.client = createApolloClient(params);
  }
  public async getPlaceDetails(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    placeId: any,
    sessionToken: string,
    placeType: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    abortController: any
  ): Promise<PlacesDetailsQuery> {
    const result: PlacesDetailsQuery = await this.client.getData(
      {
        query: GET_PLACE_DETAILS_RESULTS,
        variables: {
          input: {
            placeId: placeId,
            sessionToken: sessionToken,
            placeType: placeType,
          },
        },
      },
      this.token,
      false,
      abortController
    );
    return result;
  }

  public async getPlaces(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    searchInput: any,
    sessionToken: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    abortController: any
  ): Promise<PlacesSearchQuery> {
    const result: PlacesSearchQuery = await this.client.getData(
      {
        query: GET_PLACE_RESULTS,
        variables: {
          searchInput: {
            input: searchInput,
            sessionToken: sessionToken,
          },
        },
      },
      this.token,
      false,
      abortController
    );
    return result;
  }
}

export const createSearchServiceClient = async (
  params: IApolloClientProps
): Promise<SearchServiceClient> => {
  const client = new SearchServiceClient(params);
  client.token = await new TokenProvider().getAnonToken();
  return client;
};
