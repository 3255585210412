import { getLoggedStatus } from "../helpers";

export const pushErrorInGA = (
  errorType: string,
  errorSubtype: string,
  message: string
): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const loggedState = getLoggedStatus();
  const data = {
    eventCategory: errorType,
    eventAction: errorSubtype,
    eventLabel: message,
    loggedState: loggedState,
  };
  window.dataLayer.push({
    event: "websiteError",
    ...data,
  });
  /* eslint-disable no-undef */
  typeof ga !== "undefined" &&
    ga("send", {
      hitType: "event",
      ...data,
    });
  /* eslint-disable no-undef */
};
