import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import localforage from "localforage";

import { memberReducer } from "./Member/member";
import { hotelReducer } from "./Hotel/hotel";
import { brandReducer } from "./Brand/brand";
import { searchReducer, searchDiscountTimeReducer } from "./Search/search";
import {
  ratesReducer,
  specialPricingReducer,
  ratePlanFilterReducer,
} from "./Rate/rate";
import { editReservationCheckoutReducer } from "./Checkout/editReservationCheckout";
import { checkoutReducer } from "./Checkout/checkout";
import { checkoutTotalReducer } from "./Checkout/checkoutTotal";
import { editCheckoutTotalReducer } from "./Checkout/editCheckoutTotal";
import { webFramedReducer } from "./WebFramed/WebFramed";
import { recentSearchReducer } from "./RecentSearch/RecentSearch";
import { initiateReservationReducer } from "./Checkout/initiateReservation";
const _localforage = localforage.createInstance({
  name: "Sonesta",
});
const rootPersistConfig = {
  key: "root",
  storage: _localforage,
  blacklist: ["form"],
};

const rootReducer = combineReducers({
  member: memberReducer,
  hotels: hotelReducer,
  brand: brandReducer,
  search: searchReducer,
  rates: ratesReducer,
  checkout: checkoutReducer,
  show_special_pricing: specialPricingReducer,
  editReservationCheckout: editReservationCheckoutReducer,
  checkoutTotal: checkoutTotalReducer,
  editReservationCheckoutTotal: editCheckoutTotalReducer,
  ratePlanFilter: ratePlanFilterReducer,
  searchDiscountSetTime: searchDiscountTimeReducer,
  isWebFramed: webFramedReducer,
  recentSearch: recentSearchReducer,
  initiateReservationResponse: initiateReservationReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);
