export const norIntegrationOfferClickEvent = (reservationID: string): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: "Nor1UpgradeClick",
    upgradeClicked: true,
    reservationID: reservationID,
  });
};
