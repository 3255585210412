import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const webFramedSlice = createSlice({
  name: 'webFramed',
  initialState: false,
  reducers: {
    checkWebFramed: (state: any, { payload }: PayloadAction<any>) => {
      return payload;
    }
  },
});

export const webFramedReducer = webFramedSlice.reducer;
export const { checkWebFramed } = webFramedSlice.actions;