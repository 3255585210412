import { guestServiceProvider } from "./ServiceProvider";

const url = "/api/abandonCartCDP";
const headers = {
  "Content-Type": "application/json",
};

interface IHandleErrors {
  error: boolean;
  errorSummary: string;
}

const handleErrors = (error: any) => {
  console.error(error);
  return {
    error: true,
    errorSummary: "Oops!! Something went wrong, please try again.",
  };
};

export const getRecentSearch = async (
  memberId: string
): Promise<void | IHandleErrors> => {
  try {
    const res = await fetch(`${url}?memberId=${memberId}`);
    return await res.json();
  } catch (error) {
    return handleErrors(error);
  }
};

export const getRecentSearchDetails = async (
  memberId: string
): Promise<void | IHandleErrors> => {
  try {
    const guestService = await guestServiceProvider();
    const res = await guestService.getRecentSearch(memberId + "");
    return res;
  } catch (error) {
    return handleErrors(error);
  }
};

export const postRecentSearch = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  payload: any
): Promise<void | IHandleErrors> => {
  try {
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
    return await res.json();
  } catch (error) {
    return handleErrors(error);
  }
};

export const postRecentSearchDetails = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  payload: any
): Promise<void | IHandleErrors> => {
  try {
    const guestService = await guestServiceProvider();
    const res = await guestService.postRecentSearch(
      payload.memberID,
      payload.startDate,
      payload.hotelID,
      payload.endDate,
      payload.roomType,
      payload.promoCode,
      payload.occupancy,
      payload.groupCode,
      payload.ratePlanFilterCode,
      payload.lastUpdated
    );
    return await res;
  } catch (error) {
    return handleErrors(error);
  }
};
