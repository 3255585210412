import { coreGetContent } from "../core";

export const fetchHotelDetailsFromCore = async (
  crsCode: string,
  source?: any | undefined
): Promise<any> => {
  const apiUrl = `/hotels?filter[status][value]=1&filter[crs_code][value]=${crsCode}&include=brand_id`;
  const data = await coreGetContent(apiUrl, source);

  return data;
};

export default fetchHotelDetailsFromCore;
