/* eslint-disable no-console */
import * as Sentry from "@sentry/gatsby";
import { CaptureConsole, HttpClient } from "@sentry/integrations";
import {
  beforeBreadcrumb,
  beforeSend,
  // beforeSendTransaction,
} from "./src/utils/sentryCustomMethods";

// Extract the environment from the current site URL
const getCurrentEnvironment = () => {
  if (typeof window !== "undefined") {
    const hostname = window.location.hostname;
    if (hostname.includes("staging.sonesta.com")) {
      return "staging";
    } else if (hostname.includes("www.sonesta.com")) {
      return "production";
    } else if (hostname.includes("mobile.sonesta.com")) {
      return "mobile";
    } else if (hostname.includes("localhost")) {
      return "development";
    }
  }
  return "unknown";
};

const environment = getCurrentEnvironment();

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      networkDetailAllowUrls: ["/.netlify/functions/upgradeReservationNor1Api"],
      networkRequestHeaders: ["X-Custom-Header"],
      networkResponseHeaders: ["X-Custom-Header"],
    }),
    new HttpClient(),
    new CaptureConsole({
      levels: ["log", "error", "debug", "assert"],
    }),
  ],
  debug: false,
  beforeBreadcrumb,
  beforeSend,
  // beforeSendTransaction,
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  denyUrls: [
    /extensions\//i, // Chrome extensions
    /^chrome:\/\//i, // Chrome internal URLs
    /http:\/\/localhost/i, // Localhost development URLs
    /http:\/\/127\.0\.0\.1/i, // Localhost IP development URLs
    /.*\.google-analytics\.com/, // Google Analytics
    /.*\.facebook\.net/, // Facebook
    /.*\.doubleclick\.net/, // DoubleClick
    /.*\.googletagmanager\.com/, // Google Tag Manager
    /.*\.azure\.com/, // Azure analytics
  ],
  environment: environment,
});
// }
