import { TokenProvider } from "../TokenProvider";
import { fetchReservationAPIConfig } from "./fetchReservationAPIConfig";
import { createAxiosClient } from "../rest/AxiosClient";

export const updateReservationCBF = async (
  data?: any | undefined,
  reservationId: string
): Promise<any> => {
  const token = await new TokenProvider().getAnonToken();
  const config = await fetchReservationAPIConfig(token);
  const axiosClient = await createAxiosClient({
    apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
  });
  try {
    const response = await axiosClient.putData(
      `/reservation/${reservationId}/transaction/modify`,
      data,
      config
    );
    return response;
  } catch (error: any) {
    console.error("Unexpected Error:", error);
  }
};

export default updateReservationCBF;
