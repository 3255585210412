export const norIntegrationOfferEvent = (
  reservationID: string,
  upgradeOffered: boolean
): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: "Nor1UpgradeOffer",
    upgradeOffered: upgradeOffered,
    reservationID: reservationID,
  });
};
