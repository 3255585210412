import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import addDays from "date-fns/addDays";
import { IAmenity } from "../../../@types/IAmenity";
import { Room } from "../../../models/room";
import { formatDate, searchDateFormat } from "../../../services/dates";
import { RootState } from "../../store";

const searchDiscountTimeSlice = createSlice({
  name: "searchDiscountTime",
  initialState: "",
  reducers: {
    setSearchDiscountTime: (_state: any, { payload }: PayloadAction<any>) => {
      return payload;
    },
  },
});

export const searchDiscountTimeReducer = searchDiscountTimeSlice.reducer;
export const { setSearchDiscountTime } = searchDiscountTimeSlice.actions;

export interface ISearchState {
  searchTypeGapi: string | null;
  customUrl: string;
  checkin: string;
  checkout: string;
  latitude: number | null;
  longitude: number | null;
  searchString: string;
  country: string;
  searchType: string;
  distance: number;
  discount: string | null;
  rooms: Room[];
  promotionCode: string | null;
  groupCode: string | null;
  ratePlanFilterCode: string | null;
  priceFilter: string | null;
  amenities: Array<IAmenity>;
  brands: {
    [key: string]: string;
  };
  showSubform: boolean;
  checkoutState: {
    [key: string]: string;
  } | null;
  previousState: null | ISearchState;
}

const _initialState: ISearchState = {
  customUrl: "",
  checkin: formatDate(new Date(), searchDateFormat),
  checkout: formatDate(addDays(new Date(), 1), searchDateFormat),
  latitude: null,
  longitude: null,
  searchString: "",
  searchTypeGapi: "",
  searchType: "",
  distance: 50,
  discount: null,
  rooms: [new Room()],
  promotionCode: null,
  groupCode: null,
  ratePlanFilterCode: null,
  priceFilter: null,
  amenities: [],
  brands: {},
  showSubform: false,
  country: "",
  checkoutState: null,
  previousState: null,
};

const searchSlice = createSlice({
  name: "search",
  initialState: _initialState,
  reducers: {
    setSearch: (state: any, { payload }: PayloadAction<any>) => {
      if (payload?.fromPage === "/checkout") {
        state.previousState = { ...state }; // Store the current state in previousState
      } else {
        state.previousState = { ...state, ...payload }; // Keep both states same if not from /checkout
      }
      // return { ...state, ...payload };
      Object.assign(state, payload);
    },
    addAmenity: (state: any, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        amenities: { ...state.amenities, [payload.key]: payload.value },
      };
    },
    deleteAmenity: (state: any, { payload }: PayloadAction<any>) => {
      /* eslint-disable no-unused-vars */
      const { [payload.key]: deletedAmenity, ...rest } = state.amenities;
      /* eslint-enable no-unused-vars */
      return { ...state, amenities: rest };
    },
    resetAmenities: (state) => {
      state.amenities = [];
    },
    addBrand: (state: any, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        brands: { ...state.brands, [payload.key]: payload.value },
      };
    },
    deleteBrand: (state: any, { payload }: PayloadAction<any>) => {
      /* eslint-disable no-unused-vars */
      const { [payload.key]: deletedBrand, ...rest } = state.brands;
      /* eslint-enable no-unused-vars */
      return { ...state, brands: rest };
    },
    resetSearch: (state: any, { payload }: PayloadAction<any>) => {
      return _initialState;
    },
    setcheckoutState: (state, action: PayloadAction<any>) => {
      state.checkoutState = action.payload;
      return state;
    },
    clearSearchInput: (state) => {
      state.searchString = "";
      state.latitude = null;
      state.longitude = null;
    },
  },
});

export const searchReducer = searchSlice.reducer;
export const {
  setSearch,
  addAmenity,
  deleteAmenity,
  resetAmenities,
  addBrand,
  deleteBrand,
  resetSearch,
  setcheckoutState,
  clearSearchInput,
} = searchSlice.actions;

//Selectors
export const searchSelector = (state: RootState): ISearchState =>
  state.search || {};
