import { gql } from "@apollo/client";

export const GET_BASIC_PROFILE_QUERY = gql`
  query GetCDPUserProfile {
    cDPUserProfile {
      memberId
      gender
      title
      birthday {
        day
        month
      }
      address {
        country
        locality
        postalCode
        region
        streetAddress1
        streetAddress2
      }
      createdDate
      email
      firstName
      id
      interests {
        businessTravel
        leisureTravel
      }
      lastName

      phones {
        work
        home
        mobile
      }
      profileType
      rewardPoints
      rewards {
        description
        isEligible
        operation
        rewardPoints
        rewardTransactionNo
        transactionDate
      }
      trips {
        itineraryId
        reservations {
          redemption {
            redeemedPoints
          }
          billing {
            costEstimate
          }
          confirmNumber
          guestFirstName
          guestLastName
          endDate
          hotel {
            address {
              country
              locality
              postalCode
              region
              streetAddress1
              streetAddress2
            }
            cmsHotelCode
            cmsHotelId
            email
            geoCoordinate {
              latitude
              longitude
            }
            id
            imageUrl
            name
            telephone
          }
          itineraryId
          loyaltyProfile {
            firstName
            id
            lastName
          }
          numberOfNights
          policy {
            checkInTime
            checkOutTime
          }
          reward {
            description
            isEligible
            operation
            rewardPoints
            rewardTransactionNo
            transactionDate
          }
          room {
            adultCount
            childCount
            roomType
          }
          startDate
          status
          visitLogNo
          status
        }
      }
    }
  }
`;

export const GET_MEMBER_BASIC_PROFILE_QUERY = gql`
  query memberBasicProfile {
    memberBasicProfile {
      memberId
      gender
      title
      birthday {
        day
        month
        __typename
      }
      address {
        country
        locality
        postalCode
        region
        streetAddress1
        streetAddress2
        __typename
      }
      createdDate
      email
      firstName
      id
      interests {
        businessTravel
        leisureTravel
        __typename
      }
      lastName
      phones {
        work
        home
        mobile
        __typename
      }
      profileType
      rewardPoints
      __typename
    }
  }
`;

export const EDIT_USER_PROFILE_MUTATION = gql`
  mutation EditUserProfile(
    $updateUserProfileRequest: UpdateUserProfileRequestInput!
  ) {
    editUserProfile(updateUserProfileRequest: $updateUserProfileRequest) {
      message
      statusCode
    }
  }
`;

export const GET_MEMBER_POINTS_ACTIVITY_QUERY = gql`
  query GetMemberPointsActivity {
    memberPointsActivity {
      __typename
      message
      statusCode
      pagination {
        page
        totalPages
        totalItems
        sortBy
      }
      results {
        __typename
        description
        isEligible
        operation
        rewardPoints
        rewardTransactionNo
        transactionDate
      }
    }
  }
`;

export const GET_MEMBER_TRIP_HISTORY_QUERY = gql`
  query MemberTripHistory($tripHistoryInput: TripHistoryInput!) {
    memberTripHistory(tripHistoryInput: $tripHistoryInput) {
      message
      statusCode
      pagination {
        page
        totalPages
        totalItems
        sortBy
      }
      results {
        itineraryId
        reservations {
          redemption {
            redeemedPoints
            __typename
          }
          billing {
            costEstimate
            __typename
          }
          confirmNumber
          guestFirstName
          guestLastName
          endDate
          hotel {
            address {
              country
              locality
              postalCode
              region
              streetAddress1
              streetAddress2
              __typename
            }
            cmsHotelCode
            cmsHotelId
            email
            geoCoordinate {
              latitude
              longitude
              __typename
            }
            crsHotelCode
            imageUrl
            name
            telephone
            __typename
          }
          loyaltyProfile {
            firstName
            memberId
            lastName
            __typename
          }
          numberOfNights
          policy {
            checkInTime
            checkOutTime
            __typename
          }
          room {
            adultCount
            childCount
            roomType
            __typename
          }
          startDate
          status
          visitLogNo
          __typename
        }
      }
    }
  }
`;
