import { TokenProvider } from "../TokenProvider";
import { fetchReservationAPIConfig } from "./fetchReservationAPIConfig";
import { createAxiosClient } from "../rest/AxiosClient";

export const createReservationCBF = async (
  data?: any | undefined,
  reservationTransactionId: string,
  source = null
): Promise<any> => {
  const token = await new TokenProvider().getAnonToken();
  const config = await fetchReservationAPIConfig(token);
  const axiosClient = await createAxiosClient({
    apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
  });

  try {
    const response = await axiosClient.postData(
      `/reservation/transaction/${reservationTransactionId}/commit`,
      data,
      config
    );
    return response;
  } catch (error: any) {
    const statusCodeMatch = error.message.match(/(\d+)/);
    if (
      statusCodeMatch &&
      statusCodeMatch?.length &&
      statusCodeMatch[0] === "408"
    ) {
      // If COMMIT API timesout status Code 408, calling STATUS API
      const statusResponse = await axiosClient.getData(
        `/reservation/transaction/${reservationTransactionId}/status`,
        config
      );
      return statusResponse;
    }
    throw error;
  }
};

export default createReservationCBF;
