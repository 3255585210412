import { differenceInDays } from "date-fns";
import { Hotel, Payment, Product, Room } from "../../@types/IDatalayers";
import { getLoggedStatus, getLoggedUserInfo } from "../helpers";
import { sha256 } from "./sha256";

export const checkoutStep2GTMDataLayer = async (
  hotel: Hotel,
  rooms: Room[],
  checkin: string,
  checkout: string,
  payment: Payment | null
): Promise<void> => {
  const totalNights = differenceInDays(new Date(checkout), new Date(checkin));
  const brandName =
    hotel.relationships &&
    hotel.relationships.brand_id &&
    hotel.relationships.brand_id.name;
  const products: Product[] = []; // Adjust 'any[]' to match the actual type of the 'products' array if possible
  const applyBucks = payment ? !!payment.with_giftcards : false;
  let bucksAmount = payment ? parseFloat(payment.giftcard_amount) : 0;
  const loggedState = getLoggedStatus();
  const totalRooms = rooms?.length;
  const { email, profileType } = getLoggedUserInfo();
  let emailHash = "";
  if (email) {
    emailHash = await sha256(email); // Ensure that email is a string before passing it to sha256 function
  }
  rooms.forEach(function (r) {
    const rate: any = r.rate;
    if (rate && rate.subTotal) {
      let roomCost = parseFloat(rate?.subTotal);
      if (applyBucks && bucksAmount > 0 && roomCost > 0) {
        if (bucksAmount <= roomCost) {
          roomCost = roomCost - bucksAmount;
          bucksAmount = 0;
        } else {
          bucksAmount = bucksAmount - roomCost;
          roomCost = 0;
        }
      }
      r.room &&
        products.push({
          name: r.room.name,
          price: roomCost,
          brand: brandName,
          hotelId: hotel.crs_code,
          hotelName: hotel.name,
          category: rate.rateCode,
          rateName: rate.name,
          quantity: totalNights,
        });
    }
  });

  // Use window?.dataLayer to handle cases when dataLayer is not available
  window?.dataLayer?.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: { step: 2, option: "All" },
        products: products,
      },
      rooms: totalRooms,
      city: hotel?.address?.locality,
      ...(email && { emailHash: emailHash }),
      ...(profileType && { profileType: profileType }),
    },
    loggedState: loggedState,
  });
};
