import { getLoggedStatus } from "../helpers";
import { Hotel, Product, Search } from "../../@types/IDatalayers";

export const addProductImpressionGTMDataLayer = (
  hotelList: Hotel[],
  search: Search
): void => {
  const products: Product[] = [];
  const loggedState = getLoggedStatus();

  hotelList.forEach((hotel, i) => {
    products.push({
      name: hotel.name,
      hotelId: hotel.crsHotelCode,
      list: search.searchString,
      category: null,
      brand: hotel.brand?.name,
      price: null,
      position: i + 1,
    });
  });
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    event: "product list impressions",
    ecommerce: {
      currencyCode: "USD",
      impressions: products,
      rooms: search?.rooms?.length,
    },
    loggedState: loggedState,
  });
};
