import { differenceInDays } from "date-fns";
import { Hotel, Product, Room } from "../../@types/IDatalayers";
import { getLoggedStatus, getLoggedUserInfo } from "../helpers";
import { sha256 } from "./sha256";

export const checkoutStep1GTMDataLayer = async (
  hotel: Hotel,
  rooms: Room[],
  checkin: string,
  checkout: string
): Promise<void> => {
  const totalNights = differenceInDays(new Date(checkout), new Date(checkin));
  const brandName =
    hotel.relationships &&
    hotel.relationships.brand_id &&
    hotel.relationships.brand_id.name;
  const products: Product[] = [];
  const loggedState = getLoggedStatus();
  const { email, profileType } = getLoggedUserInfo();
  let emailHash = "";
  if (email) {
    emailHash = await sha256(email);
  }
  rooms.forEach(function (r) {
    const rate = r.rate;
    r.room &&
      rate &&
      products.push({
        name: r.room.name,
        price: rate.subTotal,
        brand: brandName,
        hotelId: hotel.crs_code,
        hotelName: hotel.name,
        category: rate.rateCode,
        rateName: rate.name,
        quantity: totalNights,
        ratecount: r.room.RateCount,
      });
  });

  // Use window?.dataLayer to handle cases when dataLayer is not available
  window?.dataLayer?.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: { step: 1, option: "All" },
        products: products,
      },
      rooms: rooms?.length,
      city: hotel?.address?.locality,
      ...(email && { emailHash: emailHash }),
      ...(profileType && { profileType: profileType }),
    },
    loggedState: loggedState,
  });
};
