import { gql } from "@apollo/client";

export const GET_PLACE_RESULTS = gql`
  query placesSearch($searchInput: PlacesSearchInput!) {
    placesSearch(placeSearchInput: $searchInput) {
      statusCode
      message
      results {
        description
        placeId
        placeType
        metadata {
          name
          customUrl
          locationUrl
          coordinates {
            latitude
            longitude
          }
          address {
            countryCode
            locality
            postalCode
          }
        }
      }
    }
  }
`;

export const GET_PLACE_DETAILS_RESULTS = gql`
  query placesDetails($input: PlaceDetailsInput!) {
    placesDetails(placeDetailsInput: $input) {
      message
      statusCode
      details {
        geometry {
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;
