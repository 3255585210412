import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const editReservationCheckoutSlice = createSlice({
  name: 'editReservationCheckoutSlice',
  initialState: {},
  reducers: {
    setEditReservationCheckout: (_state: any, { payload }: PayloadAction<any>) => {
      return payload;
    },
    updateReservationRoom: (state: any, { payload }: PayloadAction<any>) => {
      state.Rooms = {...state.Rooms, [payload.id]: payload};
      return state;
    }
  },
});

export const editReservationCheckoutReducer = editReservationCheckoutSlice.reducer;
export const { setEditReservationCheckout, updateReservationRoom } = editReservationCheckoutSlice.actions;

