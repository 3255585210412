import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initiateReservationResponseSlice = createSlice({
  name: "initiateReservationResponseSlice",
  initialState: {},
  reducers: {
    setInitiateReservationResponse: (
      _state: any,
      { payload }: PayloadAction<any>
    ) => {
      return payload;
    },
  },
});

export const initiateReservationReducer =
  initiateReservationResponseSlice.reducer;
export const { setInitiateReservationResponse } =
  initiateReservationResponseSlice.actions;
