import {
  IATA_MEMBER_ID_LENGTH,
  TP_REDEMPTION_RATE,
} from "../../@types/Constants";
import { ICheckout, IRoom, IRoomService } from "./types";
import { store } from "../../redux/store";

export const createReservationPayload = (
  checkout: ICheckout,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ccDetails: any,
  iataId: string,
  showIATA: boolean
): any => {
  let isRedemptionRate;
  const roomsForRequest: Array<any> = [];
  const memberState = store.getState().member;
  const rooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
  const roomIDs = checkout.Rooms ? Object.keys(checkout.Rooms) : [];
  rooms.forEach((room: IRoom, index: number) => {
    // create room services object
    const roomServices: Array<any> = [];
    room.services &&
      Object.values(room.services).forEach((ser: IRoomService) => {
        roomServices.push({
          description: ser.Description,
          quantity: ser.AvailedQuantity,
          serviceInventoryCode: ser.ServiceInventoryCode,
          // ServiceRPH: ser.ServiceRPH,
          total:
            (parseFloat(ser.Price) + parseFloat(ser.Tax)) * ser.AvailedQuantity,
        });
      });

    const roomObj = {
      roomId: roomIDs[index],
      roomServices: roomServices,
    };

    if (TP_REDEMPTION_RATE.includes(room.rate.RateCode)) {
      isRedemptionRate = true;
    }

    roomsForRequest.push(roomObj);
  });

  const guestId = checkout?.GuestDetails?.id
    ? checkout?.GuestDetails?.id
    : memberState.isLoggedIn && isRedemptionRate
    ? memberState.crmBasicProfile?.memberId
    : "";

  const requestJSON = {
    specialRequest: checkout?.SpecialRequests ? checkout.SpecialRequests : "",
    roomsList: roomsForRequest,
    emailTemplate: isRedemptionRate ? "TPRedeem" : "Guestconf",
    guestId: guestId,
    signupAsMember: checkout?.GuestDetails?.helloRewardsSignup
      ? checkout.GuestDetails.helloRewardsSignup
      : false,
    creditCardDetail: ccDetails,
    ...(showIATA && {
      iataNumber:
        iataId && iataId.length == IATA_MEMBER_ID_LENGTH ? iataId : null,
    }),
  };

  return requestJSON;
};

export default createReservationPayload;
