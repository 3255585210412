export const fetchReservationAPIConfig = async (token: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": `${process.env.GATSBY_CDP_SUBSCRIPTION_KEY}`,
      Authorization: `Bearer ${token}`,
      "Api-Version": `${process.env.GATSBY_CDP_API_VERSION}`,
    },
  };
  return config;
};
