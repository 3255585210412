import { v4 as uuidv4 } from "uuid";

/**
 *
 * @param {number} adults
 * @param {number} children
 */

export class Room {
  public id: string;
  public adults: number;
  public children: number;
  public childrenAges: Array<string | number>;
  public room: {
    name: string;
  };

  constructor(
    adults = 1,
    children = 0,
    childrenAges = [],
    room = { name: "" }
  ) {
    this.id = uuidv4();
    this.adults = adults;
    this.children = children;
    this.childrenAges = childrenAges;
    this.room = room;
  }
}
