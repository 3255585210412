/**
 * Retrieves a value from the browser's local storage by key.
 * @param {string} key - They key value under which the item is stored.
 * @returns {Object/undefined} - The item (if found).
 * @constructor
 */
import { isBrowser } from "./helpers";
const GetLocalStorageValue = (key: string): any => {
  let returnValue = null;
  const fetchedItemString: string | null = isBrowser ? window.localStorage.getItem(key) : null;

  if(fetchedItemString) {
    const fetchedItem = JSON.parse(fetchedItemString);
    const now = new Date().getTime();

    if(fetchedItem.expiry === 0 || now < fetchedItem.expiry) {
      returnValue = fetchedItem.value;
    } else {
      window.localStorage.removeItem(key);
    }
  }

  return returnValue;
};

/**
 * Stores a value in the browser's local storage.
 * @param {string} key - The key value to store the object under.
 * @param {Object} value - The value to be stored in local storage.
 * @param {integer} value - The expiry time of local storage in miliseconds.

 * @constructor
 */
const SetLocalStorageValue = (key: string, value: string | boolean | number, ttl=0): void => {
  const now = new Date().getTime();
  const valueWithExpry = {
    value: value,
    expiry: ttl > 0 ? now + ttl : ttl // expiry 0 for no expiry date
  };
  isBrowser && window.localStorage.setItem(key, JSON.stringify(valueWithExpry));
};

/**
 * Removes a value from the browser's local storage by key. If no key is
 * provided, all values are cleared from local storage.
 * @param {string} key - If not provided, will clear all values from local storage
 * @constructor
 */
const ClearLocalStorageValue = (key: string): void => {
  if (key) {
    isBrowser && window.localStorage.removeItem(key);
  }
  else {
    isBrowser && window.localStorage.clear();
  }
};

export const Storage = {
  SetLocalStorageValue: SetLocalStorageValue,
  GetLocalStorageValue: GetLocalStorageValue,
  ClearLocalStorageValue: ClearLocalStorageValue
};
