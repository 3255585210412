import { Action, configureStore } from "@reduxjs/toolkit";
import thunk, { ThunkAction } from 'redux-thunk';

import rootReducer from "./slices";

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
