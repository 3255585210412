import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const _initialState: any = {};

const editCheckoutTotalSlice = createSlice({
  name: 'editCheckoutTotal',
  initialState: _initialState,
  reducers: {
    addReservationEditCheckoutTotal: (_state: any, { payload }: PayloadAction<any>) => {
      return payload;
    }
  },
});

export const editCheckoutTotalReducer = editCheckoutTotalSlice.reducer;
export const { addReservationEditCheckoutTotal } = editCheckoutTotalSlice.actions;

