import { TokenProvider } from "./TokenProvider";
import { createAxiosClient } from "./rest/AxiosClient";
import { TP_REDEMPTION_RATE } from "../@types/Constants";
import { store } from "../redux/store";

export const getMemberPoints = async (memberId: string): Promise<any> => {
  const token = await new TokenProvider().getAnonToken();
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": `${process.env.GATSBY_CDP_SUBSCRIPTION_KEY}`,
      Authorization: `Bearer ${token}`,
      "Api-Version": `${process.env.GATSBY_CDP_API_VERSION}`,
    },
  };

  const axiosClient = await createAxiosClient({
    apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
  });

  try {
    const response = await axiosClient.getData(
      `/redemption/member/${memberId}/points`,
      config
    );
    return response;
  } catch (error: any) {
    console.error("Unexpected Error:", error);
  }
};

export const checkUserRedemptionEligiblity = (
  userPoints: number,
  pointsRequiredPerDay: number,
  numOfNights: number
): boolean => {
  if (userPoints && pointsRequiredPerDay && numOfNights) {
    if (userPoints >= pointsRequiredPerDay * numOfNights) {
      return true;
    }
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const checkRedemptionRateInCheckout = (checkout: any): boolean => {
  if (checkout && checkout.Rooms) {
    if (Object.keys(checkout.Rooms).length === 1) {
      const roomDetails: any = Object.values(checkout.Rooms)[0];
      if (
        roomDetails &&
        roomDetails?.rate &&
        TP_REDEMPTION_RATE.includes(roomDetails?.rate?.rateCode)
      ) {
        return true;
      }
    }
  }
  return false;
};

interface Reservation {
  redemption: {
    redeemedPoints: number;
  };
  billing: {
    costEstimate: number | null;
  };
  confirmNumber: string;
}
interface Trip {
  itineraryId: string;
  reservations: Reservation[];
}

export const checkUsedRedemptionPointsforReservation = (
  reservationId: string
): void | number => {
  const currentStoreState = store.getState();
  const upcomingReservations =
    currentStoreState?.member?.upcomingReservations || {};
  const recentStays = currentStoreState?.member?.recentStays || {};
  const cancelledReservations =
    currentStoreState?.member?.cancelledReservations || {};

  const allTrips = [
    ...Object.values(upcomingReservations || {}),
    ...Object.values(recentStays || {}),
    ...Object.values(cancelledReservations || {}),
  ];

  const userTrips = allTrips || [];
  const reservation = userTrips.find(
    (trip) => trip.confirmationNo === reservationId
  );
  if (reservation) {
    return reservation.redeemedPoints;
  }
  return 0;
  // Commenting old code for testing new code
  // if (
  //   reservationId &&
  //   Object.keys(upcomingStays).length > 0 &&
  //   upcomingStays[reservationId]
  // ) {
  //   return upcomingStays[reservationId].redeemedPoints;
  // }
  // return 0;
};

export const filterRedemptionRatesFromRooms = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  hotelData: any
): Promise<void> => {
  // Removing TPRNR rate from Availability API response.
  try {
    const updatedRooms = await Promise.all(
      hotelData.rooms.map(async (room: any) => {
        const updatedRoomRates = room.roomRates.filter(
          (rate: any) =>
            rate.__typename !== "QueryRoomRateInfo" ||
            TP_REDEMPTION_RATE.includes(rate.rateCode)
        );
        return {
          ...room,
          roomRates: updatedRoomRates,
        };
      })
    );

    const updatedHotelData = {
      ...hotelData,
      rooms: updatedRooms,
    };

    return updatedHotelData;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const checkEditFlowByPathname = (pathname: string): string | boolean => {
  const editFlowPathnames = [
    "/edit-reservation/select-room",
    "/edit-reservation/select-rate",
    "edit-reservation/select-payment",
  ];
  return pathname && editFlowPathnames.includes(pathname);
};
