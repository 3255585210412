import { differenceInDays } from "date-fns";
import { getLoggedStatus } from "../helpers";
import { Hotel } from "../../@types/IDatalayers";

export const addToCartGTMDataLayer = (
  hotel: Hotel,
  room: { name: string },
  rate: { rateCode: string },
  price: number,
  checkin: string,
  checkout: string,
  totalRooms: number
): void => {
  const totalNights = differenceInDays(new Date(checkout), new Date(checkin));
  const brandName = hotel.relationships?.brand_id?.name;
  const loggedState = getLoggedStatus();

  window?.dataLayer?.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "USD",
      add: {
        products: [
          {
            name: hotel.name,
            hotelId: hotel.crs_code,
            variant: room.name,
            price: price,
            brand: brandName,
            category: rate?.rateCode,
            quantity: totalNights,
          },
        ],
      },
      city: hotel?.address?.locality,
      rooms: totalRooms,
    },
    loggedState: loggedState,
  });
};
