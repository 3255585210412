import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRecentSearchDetails } from "../../../services/recent-search";
import { AppThunk } from "../../store";

const initialState = null;

const recentSearchSlice = createSlice({
  name: "recentSearch",
  initialState,
  reducers: {
    setRecentSearch: (state, action: PayloadAction<any>) => {
      state = action.payload;
      return state;
    },
    clearRecentSearch: (state) => {
      state = null;
      return state;
    },
  },
});

export const fetchRecentSearchList =
  (memberId: string, isAbandonCart: boolean): AppThunk =>
  async (dispatch) => {
    if (!memberId) return;

    let data = await getRecentSearchDetails(memberId + "");
    data = data?.abandonCartbyMemberID?.abandonCart;
    if (data) {
      dispatch(
        setRecentSearch({
          occupancy: JSON.parse(data?.occupancy),
          isAbandonCart: !!isAbandonCart,
          ...data,
        })
      );
    }
  };

export const { setRecentSearch, clearRecentSearch } = recentSearchSlice.actions;
export const recentSearchReducer = recentSearchSlice.reducer;
