import { getLoggedStatus } from "../helpers";
import { Hotel, Room, Search } from "../../@types/IDatalayers";
import { TP_REDEMPTION_RATE } from "../../@types/Constants";

export const addPropertyRoomsGTMDataLayer = (
  hotel: Hotel,
  hotelRooms: Room[],
  search: Search,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  hotelCheckout: any
): void => {
  const brandName =
    hotel.relationships &&
    hotel.relationships.brand_id &&
    hotel.relationships.brand_id.name;
  const loggedState = getLoggedStatus();
  const {
    checkin,
    checkout,
    discount,
    distance,
    groupCode,
    promotionCode,
    searchString,
    rooms,
  } = search;

  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const totalRooms = search?.rooms?.length;
  hotelRooms.forEach((room: any) => {
    let isTPRNR = false;
    room?.Rates?.forEach((element: any) => {
      if (element.RateCode && TP_REDEMPTION_RATE?.includes(element.RateCode))
        isTPRNR = true;
    });
    const eventName = room.quantity > 0 ? "AvailableRoom" : "SoldoutRoom";
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        hotel: hotel.name,
        hotelId: hotel.crs_code,
        brand: brandName,
        rooms: totalRooms,
        city: hotel?.address?.locality,
        search: {
          searchString: searchString,
          checkin: checkin,
          checkout: checkout,
          rooms: rooms,
          discount: discount,
          distance: distance,
          groupCode: groupCode,
          promotionCode: promotionCode,
        },
        roomName: room.name,
        isUserCanRedeemThisRoom: isTPRNR && hotelCheckout?.isEligible,
        requiredPointsForRedemption: hotelCheckout?.requiredPoints || 0,
        isTPRNR,
      },
      loggedState: loggedState,
    });
  });
};
