export const travelPassEventsGTMDataLayer = (
  status: string,
  source: string
): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: "Enrollment",
    status: status,
    source: source,
  });
};
