import { getLoggedStatus } from "../helpers";

export const sendLoginStateInGA = (
  loggedIn: boolean | undefined,
  pageUrl: string,
  userData: any
): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const loggedState =
    typeof loggedIn === "undefined"
      ? getLoggedStatus()
      : loggedIn
      ? "Logged In"
      : "Logged Out";
  const data = {
    eventCategory: "CaptureLoggedState",
    eventLabel: loggedState,
    pageUrl: pageUrl,
    memberId: userData?.memberId,
    profileType: userData?.profileType,
    upcomingTrip: userData?.upcomingTrip,
    daysLeftForUpcomingTrip: userData?.daysLeftForUpcomingTrip,
    hotelIdOfLastStay: userData?.hotelIdOfLastStay,
    rewardPoints: userData?.rewardPoints,
  };

  window.dataLayer.push({
    event: "CaptureLoggedState",
    ...data,
  });
  /* eslint-disable no-undef */
  if (typeof ga !== "undefined") {
    ga("set", "dimension9", loggedState);
    ga("send", {
      hitType: "event",
      ...data,
    });
  }
  /* eslint-disable no-undef */
};
