import { TokenProvider } from "../TokenProvider";
import { fetchReservationAPIConfig } from "./fetchReservationAPIConfig";
import { createAxiosClient } from "../rest/AxiosClient";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const cancelRedemtionReservation = async (params: any): Promise<any> => {
  const data = {
    memberId: params.memberId,
    isMember: params.isRedemption,
    lastname: params.lastName,
  };
  try {
    const token = await new TokenProvider().getAnonToken();
    const config = await fetchReservationAPIConfig(token);
    const axiosClient = await createAxiosClient({
      apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}`,
    });
    const response = await axiosClient.deleteData(
      `/redemption/reservation/${params.resId}/cancel`,
      { ...config, data: data }
    );
    return response;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};

export default cancelRedemtionReservation;
