import { ApolloBaseClient, IApolloClientProps } from "./ApolloBaseClient";

export class ApolloClient extends ApolloBaseClient {
  public token: any;

  constructor(params: IApolloClientProps) {
    super(params);
  }

  public async getData(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    query: any,
    token: string,
    isMember: boolean,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    abortController: any,
    fetchPolicy: boolean,
    isSynxsAPI: boolean
  ): Promise<void> {
    try {
      const effectiveFetchPolicy = fetchPolicy ? "network-only" : "no-cache";
      const response = await this.client.query({
        query: query.query,
        variables: query.variables,
        fetchPolicy: effectiveFetchPolicy,
        context: {
          headers: this.modifyHeaders(token, isMember, isSynxsAPI),
          fetchOptions: abortController
            ? {
                signal: abortController.signal,
              }
            : {},
        },
      });
      return this.processResponse(response);
    } catch (error) {
      if (error.message.includes("aborted")) {
        //do nothing
      } else {
        throw error;
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async postData(
    mutation: any,
    token: any,
    isMember: boolean
  ): Promise<void> {
    try {
      const response: any = await this.client.mutate({
        mutation: mutation.mutation,
        variables: mutation.variables,
        context: {
          headers: this.modifyHeaders(token, isMember, mutation.variables),
        },
      });
      return this.processResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private modifyHeaders(token: any, isMember: boolean, isSynxsAPI: boolean) {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Api-Version": `${
        isMember
          ? process.env.GATSBY_CDP_API_VERSION_FOR_USER_PROFILE_QUERY
          : isSynxsAPI
          ? process.env.GATSBY_GAPI_SYNXIS_API_VERSION
          : process.env.GATSBY_CDP_API_VERSION
      }`,
      // "Api-Version": `${isMember ? "v2.0" : "v1.1"}`,
    };
    return headers;
  }
  private processResponse(response: any) {
    if (response.error) {
      console.error(response.error.message);
      return response.error;
    } else {
      return response?.data;
    }
  }
}
export const createApolloClient = (
  params: IApolloClientProps
): ApolloClient => {
  return new ApolloClient(params);
};
