const apiUrl = process.env.GATSBY_CDP_AUTH0_AUDIENCE;

const environment =
  typeof window !== "undefined" ? window.location.hostname : "unknown";

// Function to add extra information to breadcrumbs or events
function addExtraInfo(data, info) {
  return {
    ...data,
    additionalInfo: {
      message: "Extra information for analysis",
      timestamp: new Date().toISOString(),
      urlPath: data.url ? new URL(data.url).pathname : null,
      userAgent: navigator.userAgent,
      environment: environment,
      ...info,
    },
  };
}

// Function to check if a message should be ignored
function shouldIgnoreMessage(message) {
  const ignoreMessages = [
    "Warning: `controlId` is ignored on `<FormLabel>` when `htmlFor` is specified.",
    "GTM - SCRIPT", // Example: ignore GTM script logs
    "HMR connected", // Example: ignore HMR connected logs
  ];
  return (
    message?.toLowerCase().includes("warning") ||
    ignoreMessages.some((ignoreMessage) => message?.includes(ignoreMessage))
  );
}

// Function to check if a URL should be ignored
function shouldIgnoreUrl(url) {
  const ignoreUrls = [
    /extensions\//i,
    /^chrome:\/\//i,
    /.*\.google-analytics\.com/,
    /.*\.facebook\.net/,
    /.*\.doubleclick\.net/,
    /.*\.googletagmanager\.com/,
  ];
  return ignoreUrls.some((pattern) => url?.match(pattern));
}

// Function to handle breadcrumb data
function handleBreadcrumbData(breadcrumb) {
  if (breadcrumb.data?.url?.includes(apiUrl)) {
    return addExtraInfo(breadcrumb.data, { apiType: "Sonesta GAPI API" });
  } else if (breadcrumb.data?.url?.includes("onetrust")) {
    return addExtraInfo(breadcrumb.data, { apiType: "OneTrust API" });
  }
  return breadcrumb.data;
}

// Function to modify or drop breadcrumbs before they are sent to Sentry
export function beforeBreadcrumb(breadcrumb) {
  if (shouldIgnoreMessage(breadcrumb.message)) {
    return null;
  }

  if (
    breadcrumb.level === "log" ||
    (breadcrumb.category === "console" && breadcrumb.level === "warning")
  ) {
    return null;
  }

  if (shouldIgnoreUrl(breadcrumb.data?.url)) {
    return null;
  }

  breadcrumb.data = handleBreadcrumbData(breadcrumb);

  return breadcrumb;
}

// Function to modify events before they are sent to Sentry
export function beforeSend(event, hint) {
  if (shouldIgnoreMessage(event.message)) {
    return null;
  }

  if (event.breadcrumbs) {
    event.breadcrumbs = event.breadcrumbs.filter(
      (breadcrumb) => !shouldIgnoreUrl(breadcrumb.data?.url)
    );
    event.breadcrumbs.forEach((breadcrumb) => {
      breadcrumb.data = handleBreadcrumbData(breadcrumb);
    });

    event.tags = {
      ...event.tags,
      apiType: event.breadcrumbs.find((breadcrumb) => breadcrumb?.data?.apiType)
        ?.data?.apiType,
    };

    event.extra = {
      ...event.extra,
      additionalInfo: event.breadcrumbs.find(
        (breadcrumb) => breadcrumb?.data?.additionalInfo
      )?.data?.additionalInfo,
    };
  }
  return event;
}
