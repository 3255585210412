import { ApolloBaseClient, IApolloClientProps } from "./ApolloBaseClient";
import { createApolloClient } from "./ApolloClient";
import { TokenProvider } from "../TokenProvider";
import { ITokenProvider } from "../@types/MemberTypes";
import {
  IUpdateUserProfileRequestInput,
  IGetProfilePointActivityInput,
  IGetMemberTripHistoryInput,
} from "../@types/RequestTypes";
import {
  EDIT_USER_PROFILE_MUTATION,
  GET_BASIC_PROFILE_QUERY,
  GET_MEMBER_POINTS_ACTIVITY_QUERY,
  GET_MEMBER_BASIC_PROFILE_QUERY,
  GET_MEMBER_TRIP_HISTORY_QUERY,
} from "./MemberQuery";
import {
  EditUserProfileMutation,
  GetCdpUserProfileQuery,
  GetMemberPointsActivityQuery,
  MemberBasicProfileQuery,
  MemberTripHistoryQuery,
} from "../../__generated__/types-member";

export class MemberClient extends ApolloBaseClient {
  protected client: any;
  public token: any;
  protected tokenProvider: ITokenProvider;

  constructor(params: IApolloClientProps) {
    super(params);

    this.client = createApolloClient(params);
    this.tokenProvider = new TokenProvider();
  }
  async getMemberProfile(): Promise<GetCdpUserProfileQuery> {
    const token = await this.tokenProvider.getUserToken();
    const result: GetCdpUserProfileQuery = await this.client.getData(
      {
        query: GET_BASIC_PROFILE_QUERY,
        variables: {},
      },
      token,
      true,
      null,
      true
    );

    return result;
  }

  async getUserProfile() {
    const token = await this.tokenProvider.getUserToken();
    const result: MemberBasicProfileQuery = await this.client.getData(
      {
        query: GET_MEMBER_BASIC_PROFILE_QUERY,
        variables: {},
      },
      token,
      true,
      null,
      true
    );

    return result;
  }

  async getMemberTripHistory(requestInput: IGetMemberTripHistoryInput) {
    const token = await this.tokenProvider.getUserToken();
    const result: MemberTripHistoryQuery = await this.client.getData(
      {
        query: GET_MEMBER_TRIP_HISTORY_QUERY,
        variables: { tripHistoryInput: requestInput },
      },
      token,
      true,
      null,
      true
    );

    return result;
  }

  async getActivityPointProfile(requestInput: IGetProfilePointActivityInput) {
    const token = await this.tokenProvider.getUserToken();
    const result: GetMemberPointsActivityQuery = await this.client.getData(
      {
        query: GET_MEMBER_POINTS_ACTIVITY_QUERY,
        variables: { paginationInput: requestInput },
      },
      token,
      true,
      null,
      true
    );

    return result;
  }

  async editUserProfile(
    requestInput: IUpdateUserProfileRequestInput
  ): Promise<EditUserProfileMutation> {
    const token = await this.tokenProvider.getUserToken();
    const result: EditUserProfileMutation = await this.client.postData(
      {
        mutation: EDIT_USER_PROFILE_MUTATION,
        variables: { updateUserProfileRequest: requestInput },
      },
      token,
      true
    );

    return result;
  }
}
export const createMemberClient = async (
  params: IApolloClientProps
): Promise<MemberClient> => {
  const client = new MemberClient(params);
  client.token = await new TokenProvider().getUserToken();
  return client;
};
