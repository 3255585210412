import { coreRequest } from "../core";
import { getCRSSubSource } from "../../utils/helpers";

export const updateReservation = async (
  data?: any | undefined,
  source = null
): Promise<any> => {
  const apiUrl = `/billing`;
  const siteId = process.env.SITE_ID || 31;
  const subSource = getCRSSubSource();
  const response = await coreRequest({
    url: apiUrl,
    data: {
      ...data,
      siteId: siteId,
      ...(subSource && { subSource: subSource }),
    },
    source: source,
    method: "put",
  });
  return response;
};

export default updateReservation;
