import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const _initialState: any = {};

const checkoutTotalSlice = createSlice({
  name: 'checkoutTotal',
  initialState: _initialState,
  reducers: {
    addCheckoutTotal: (_state: any, { payload }: PayloadAction<any>) => {
      return payload;
    }
  },
});

export const checkoutTotalReducer = checkoutTotalSlice.reducer;
export const { addCheckoutTotal } = checkoutTotalSlice.actions;

