import { ICheckout, IRoomRate, IRoomService, IRoom } from "./types";

export const createResRequestJSON = (checkout: ICheckout): any => {
  const guests = checkout.GuestDetails || {};
  const rooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
  const roomsForRequest: Array<any> = [];
  const payment = checkout.Payment;
  const applyBucks = !!payment.with_giftcards;
  const isResubmitted = checkout?.isResubmitted || false;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let bucksAmount = parseFloat(payment.giftcard_amount);
  let appliedBucks = 0;
  const currency = rooms && rooms.length ? rooms[0].rate?.currencyCode : "USD";
  rooms.forEach((room: IRoom) => {
    // create room rate request object
    const roomRates = []; //@TODO : consider gift card amount
    if (
      room.rate &&
      room.rate.roomRateNightly &&
      room.rate.roomRateNightly.length > 1
    ) {
      //multi nights
      room.rate.roomRateNightly.forEach((rate: IRoomRate) => {
        let amountBeforeTax = parseFloat(rate.rate);
        if (applyBucks && bucksAmount > 0 && amountBeforeTax > 0) {
          if (bucksAmount <= amountBeforeTax) {
            amountBeforeTax = amountBeforeTax - bucksAmount;
            appliedBucks += bucksAmount;
            bucksAmount = 0;
          } else {
            bucksAmount = bucksAmount - amountBeforeTax;
            appliedBucks += amountBeforeTax;
            amountBeforeTax = 0;
          }
        }
        roomRates.push({
          AmountBeforeTax: amountBeforeTax,
          EffectiveDate: rate.effectiveDate.substring(0, 10),
          ExpireDate: rate.expireDate.substring(0, 10),
        });
      });
    } else {
      // add rates for single night.
      let amountBeforeTax = parseFloat(room.rate.subTotal);

      if (applyBucks && bucksAmount > 0 && amountBeforeTax > 0) {
        if (bucksAmount <= amountBeforeTax) {
          amountBeforeTax = amountBeforeTax - bucksAmount;
          appliedBucks += bucksAmount;
          bucksAmount = 0;
        } else {
          bucksAmount = bucksAmount - amountBeforeTax;
          appliedBucks += amountBeforeTax;
          amountBeforeTax = 0;
        }
      }

      roomRates.push({
        AmountBeforeTax: amountBeforeTax,
        EffectiveDate: checkout.Start,
        ExpireDate: checkout.End,
      });
    }

    // create room services object
    const roomServices: Array<any> = [];
    room.services &&
      Object.values(room.services).forEach((ser: IRoomService) => {
        roomServices.push({
          Description: ser.Description,
          Quantity: ser.AvailedQuantity,
          ServiceInventoryCode: ser.ServiceInventoryCode,
          ServiceRPH: ser.ServiceRPH,
          Total:
            (parseFloat(ser.Price) + parseFloat(ser.Tax)) * ser.AvailedQuantity,
        });
      });

    const roomObj = {
      Count: {
        Adult: room.adults,
        Child: {
          numGuests: room.children,
          ages: room.childrenAges,
        }, //room.children || 0
      },
      RoomTypeCode: room.room.RoomCode,
      RatePlanCode: room.rate.RateCode,
      RoomRates: roomRates,
      roomServices: roomServices,
    };
    //@TODO Need to specifically test for groupCode
    // if(checkout.Crs == 'windsurfer' && checkout.groupCode) {
    //   roomObj.RatePlanCode = `G:${checkout.groupCode}`; // Not sure if room.rate.RateCode and checkout.groupCode would be same
    // }
    roomsForRequest.push(roomObj);
  });
  if (applyBucks) {
    payment.giftcard_amount = Math.ceil(appliedBucks);
  }
  let loyalLevel = null;
  if (guests.profileType) {
    switch (guests.profileType) {
      case "Member":
        loyalLevel = "1";
        break;
      case "Preferred":
        loyalLevel = "2";
        break;
      case "Elite":
        loyalLevel = "3";
        break;
      default:
        loyalLevel = "1";
    }
  }

  const request: any = {
    payment: payment,
    reservation: {
      Rooms: roomsForRequest,
      Start: checkout.Start,
      End: checkout.End,
      crs: checkout.Crs,
      HotelCode: checkout.HotelCode,
      NumberOfUnits: 1,
      ResGuests: [
        {
          Email: guests.email,
          GivenName: guests.firstName,
          Surname: guests.lastName,
          PhoneNumber: guests.phoneNumber,
          MembershipID: guests.id,
        },
      ],
      PromotionCode: checkout.promotionCode,
      EmailTemplate: "Guestconf",
      MembershipID: guests.id,
      ProgramID: "Travel Pass",
      LoyalLevel: loyalLevel,
      CurrencyCode: currency,
      isResubmitted: isResubmitted,
    },
  };
  if (checkout.groupCode) {
    //TODO:
    //request.reservation.InvBlockCode = checkout.groupCode;
    // request.reservation.RatePlanCode = `G:${checkout.groupCode}`;
  }
  if (checkout.SpecialRequests) {
    request.reservation.SpecialRequests = checkout.SpecialRequests;
  }

  return request;
};

export default createResRequestJSON;
