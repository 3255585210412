import { getLoggedStatus } from "../helpers";
import { Hotel, Search } from "../../@types/IDatalayers";
export const addHotelsAvailabilityGTMDataLayer = (
  hotel: Hotel,
  search: Search,
  soldout: boolean
): void => {
  const {
    checkin,
    checkout,
    discount,
    distance,
    groupCode,
    promotionCode,
    searchString,
    rooms,
    amenities,
    brands,
  } = search;
  const loggedState = getLoggedStatus();

  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const eventName = soldout ? "SoldOutProperty" : "AvailableProperty";
  window.dataLayer.push({
    event: eventName,
    ecommerce: {
      search: {
        searchString: searchString,
        checkin: checkin,
        checkout: checkout,
        rooms: rooms,
        discount: discount,
        distance: distance,
        groupCode: groupCode,
        promotionCode: promotionCode,
        amenitiesFilter: amenities,
        brandFilter: brands,
      },
      name: hotel.name,
      hotelId: hotel.crsHotelCode,
      brand: hotel.brand?.name,
    },
    loggedState: loggedState,
  });
};
